




import AdminVisitForm from '@/components/forms/AdminVisitForm.vue';
import { Component, Prop } from 'vue-property-decorator';
import AppComponent from '@/mixins/ComponentMixin.vue';


@Component({ components: { AdminVisitForm } })
export default class AdminVisit extends AppComponent {
  @Prop({ type: String, required: false }) public visitId: string;

  public visitUpdated() {
    this.$router.push('/main/admin/visits');
  }

  public cancel() {
    this.$router.push('/main/admin/visits');
  }
}
